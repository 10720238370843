import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "currentColor",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M7 4C7 3.44772 6.55228 3 6 3C5.44772 3 5 3.44772 5 4V13.1716C4.5821 13.3193 4.19808 13.5593 3.87868 13.8787C3.31607 14.4413 3 15.2044 3 16C3 16.7956 3.31607 17.5587 3.87868 18.1213C4.19808 18.4407 4.5821 18.6807 5 18.8284V20C5 20.5523 5.44772 21 6 21C6.55228 21 7 20.5523 7 20V18.8284C7.4179 18.6807 7.80192 18.4407 8.12132 18.1213C8.68393 17.5587 9 16.7956 9 16C9 15.2044 8.68393 14.4413 8.12132 13.8787C7.80192 13.5593 7.4179 13.3193 7 13.1716V4ZM13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4V5.17157C10.5821 5.31933 10.1981 5.55927 9.87868 5.87868C9.31607 6.44129 9 7.20435 9 8C9 8.79565 9.31607 9.55871 9.87868 10.1213C10.1981 10.4407 10.5821 10.6807 11 10.8284V20C11 20.5523 11.4477 21 12 21C12.5523 21 13 20.5523 13 20V10.8284C13.4179 10.6807 13.8019 10.4407 14.1213 10.1213C14.6839 9.55871 15 8.79565 15 8C15 7.20435 14.6839 6.44129 14.1213 5.87868C13.8019 5.55927 13.4179 5.31933 13 5.17157V4ZM19 4C19 3.44772 18.5523 3 18 3C17.4477 3 17 3.44772 17 4V13.1716C16.5821 13.3193 16.1981 13.5593 15.8787 13.8787C15.3161 14.4413 15 15.2043 15 16C15 16.7957 15.3161 17.5587 15.8787 18.1213C16.1981 18.4407 16.5821 18.6807 17 18.8284V20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20V18.8284C19.4179 18.6807 19.8019 18.4407 20.1213 18.1213C20.6839 17.5587 21 16.7957 21 16C21 15.2043 20.6839 14.4413 20.1213 13.8787C19.8019 13.5593 19.4179 13.3193 19 13.1716V4ZM18 17C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16C17 16.5523 17.4477 17 18 17ZM7 16C7 16.5523 6.55228 17 6 17C5.44772 17 5 16.5523 5 16C5 15.4477 5.44772 15 6 15C6.55228 15 7 15.4477 7 16ZM12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}